#incremental-logo svg {
  height: 300px;
  width: 300px;
}

#incremental-logo svg path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s linear forwards;
}

#incremental-logo svg g {
  animation: fill-in 2s 2s ease-out forwards;
  fill-opacity: 0;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}
